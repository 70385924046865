import React from "react";
import styles from "./user-message.module.css";
import { IconButton } from "@fluentui/react";
import { messageButtons } from "../../../styles/fluentStyles";

const UserMessage = ({ message }) => {
    return (
        <div className={styles.userMessage}>
            <div className={styles.msg}>
                <p>{message.text}</p>
            </div>
            <div className={styles.msgDate}>
                <span>{message.timestamp}</span>
            </div>
            <div className={styles.msgButtons}>
                <IconButton
                    className={messageButtons}
                    iconProps={{iconName: 'Delete'}}
                    title="Delete"
                    ariaLabel="Delete"
                    onClick={() => alert('Delete Button STC')}
                />
                <IconButton
                    className={messageButtons}
                    iconProps={{iconName: 'Sync'}}
                    title="History"
                    ariaLabel="History"
                    onClick={() => alert('History Button STC')}
                />
            </div>
        </div>
    )
};

export default UserMessage;