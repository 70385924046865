import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { initializeIcons } from '@fluentui/react';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { store, persistor } from './app/store'; // Import persistor along with store
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './config/authConfig';
import '../src/components/MainComponents/Webcomponents/strings/i18n';

initializeIcons();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </PersistGate>
  </Provider>
);
