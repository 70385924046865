// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { msalConfig, loginRequest, msalInstance } from '../config/authConfig';


export const loginUser = createAsyncThunk('auth/loginUser', async (_, { rejectWithValue }) => {
    try {
        console.log("Initiating loginPopup...");
        // Create login popup from B2C
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        // Assign the response from the B2C popup to a variable
        const account = loginResponse.account;
        console.log("Login Success!");

        // Ensure account and idTokenClaims are available before proceeding
        if (account) {
            const idTokenClaims = account.idTokenClaims || {};  // Default to empty object to avoid undefined errors
            
            // Acquire the access token silently after successful login (config)
            const accessTokenRequest = {
                scopes: ["https://endectab2c.onmicrosoft.com/endecta_webAPI/tasks.read"],
                account: account,
            };
            //related to the access token soon
            let accessTokenResponse;


            // Create a request after the user logs in to get the access token
            try {
              accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
              console.log("Silent Token Acquisition Successful!");
            } catch (silentError) {
              console.warn("Silent token acquisition failed; attempting popup...");
              // If silent acquisition fails, try to acquire the token interactively
              accessTokenResponse = await msalInstance.acquireTokenPopup(accessTokenRequest);
              console.log("Interactive Token Acquisition Successful!");
            }
            


            // If there isnt an access token in the response then we throw an error
            if (!accessTokenResponse || !accessTokenResponse.accessToken) {
              throw new Error("Failed to acquire access token");
            }


            //Create a user profile object with the information we need
            const userProfile = {
                username: account.username,
                name: idTokenClaims.display_name || idTokenClaims.name || idTokenClaims.given_name || "Anonymous",
                idToken: loginResponse.idToken,
                accessToken: accessTokenResponse.accessToken, // Adding accessToken to userProfile
                expiresOn: accessTokenResponse.expiresOn, // Adding expiresOn to userProfile
                sub: idTokenClaims.sub,  // User's unique ID (subject claim)
                oid: idTokenClaims.oid || null  // Optional: Azure AD Object ID
            };
            // Set the wondows href location if the user profile has an oid
            if (userProfile.oid){
                window.location.href = '/chat';
            }
            //Return the users profile for use in the store 
            return userProfile;
        } else {
            console.error("Account not found in login response");
            return rejectWithValue("Account information is missing in the login response.");
        }
        //Catch errors in the process. 
    } catch (e) {
        console.error("Login failed:", e);  // Improved error handling
        return rejectWithValue(e.message);
    }
});

export const refreshAccessToken = createAsyncThunk('auth/refreshAccessToken', async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const account = state.auth.user;

    if (!account) {
        return rejectWithValue("No user is logged in");
    }

    const accessTokenRequest = {
        scopes: ["https://endectab2c.onmicrosoft.com/endecta_webAPI/tasks.read"],
        account,
    };

    try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        console.log("Silent Token Acquisition Successful!");
        return {
            accessToken: accessTokenResponse.accessToken,
            expiresOn: accessTokenResponse.expiresOn,
        };
    } catch (e) {
        console.error("Failed to refresh access token:", e);
        return rejectWithValue(e.message);
    }
});

// Redux Slice definition for authentication
// Contains all state varibales and the functions to update or change them.
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        expiresOn: null,
        isAuthenticated: false,
        loading: false,
        error: null,
    },
    reducers: {
        logoutUser: (state) => {
            msalInstance.logout();
            state.user = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.expiresOn = action.payload.expiresOn;
                state.isAuthenticated = true;
                state.loading = false;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.user.accessToken = action.payload.accessToken;
                state.user.expiresOn = action.payload.expiresOn;
                state.isAuthenticated = true;
            });
    }
});

export const { logoutUser } = authSlice.actions;  
export default authSlice.reducer;
