import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendMessageToAI, fetchChatsFromDeltaTable } from '../api/chatApi';
import { setCurrentConversation, updateConversationMessages } from './conversationsSlice';
import { v4 as uuidv4 } from 'uuid';

// Thunk to fetch AI response
export const fetchAiResponse = createAsyncThunk(
  'chat/fetchAiResponse',
  async (userMessage, { getState, rejectWithValue, dispatch }) => {
    try {
      // Get authentication details and current session details from the state
      const state = getState();
      const token = state.auth?.user?.idToken;  // JWT token from authSlice
      const user_id = state.auth?.user?.oid;    // User ID (oid) from authSlice
      const session_id = state.conversations?.currentConversation; // Get current session ID from conversationsSlice

      if (!token || !user_id) {
        throw new Error('Missing authentication information');
      }

      if (!session_id) {
        throw new Error('No active conversation session');
      }

      dispatch(updateConversationMessages({
        sessionId: session_id,
        messages: [
          { sender: 'user', text: userMessage, date: new Date().toISOString() },
        ]
      }));

      // Send the message along with the user's token, user_id, and session_id
      const aiResponse = await sendMessageToAI(userMessage, token, user_id, session_id);

      const aiText = aiResponse?.content || "Default response if content is missing";
      
      const retrieverMetadata = aiResponse?.retriever_metadata || []; // New metadata from RAG Chain

      // Dispatch an action to update the conversation messages in conversationsSlice
      dispatch(updateConversationMessages({
        sessionId: session_id,
        messages: [
          { sender: 'bot', text: aiText, date: new Date().toISOString(), retriever_metadata: retrieverMetadata },
        ]
      }));

      return { userMessage, aiText, retrieverMetadata };
    } catch (ex) {
      console.error('Error fetching AI response:', ex);
      return rejectWithValue(ex.message); // Use rejectWithValue to capture the error
    }
  }
);

// Thunk to fetch all existing conversations from Delta Table



// Initial states for when the chatslice is first loaded.
const chatSlice = createSlice({
  name: 'chatbot',
  initialState: {
    isChatPaneOpen: false,
    isInsightPaneOpen: false,
    insights: [], // Updated to store retriever metadata
    isControlMenuOpen: true,
    loading: false,
    error: null,
  },
  // Reducers are functions that take the current state and an action, and return a new state
  reducers: {
    toggleChatPane: (state) => {
      state.isChatPaneOpen = !state.isChatPaneOpen;
    },
    toggleInsightPane: (state) => {
      state.isInsightPaneOpen = !state.isInsightPaneOpen;
    },
    setInsights: (state, action) => {
      state.insights = action.payload;
    },
    toggleControlMenu: (state) => {
      state.isControlMenuOpen = !state.isControlMenuOpen;
    },
  },
  // Extra reducers are reducers that are created by createAsyncThunk
  extraReducers: (builder) => {
    builder
      .addCase(fetchAiResponse.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state when starting a new request
      })
      .addCase(fetchAiResponse.fulfilled, (state, action) => {
        const { retrieverMetadata } = action.payload;
        state.insights = retrieverMetadata;
        state.loading = false;
      })
      .addCase(fetchAiResponse.rejected, (state, action) => {
        state.error = action.payload; // Set error message
        state.loading = false;
      })
  },
});

// Needed exports to reuse elsewhere
export const {
  toggleControlMenu,
  setInsights,
  toggleChatPane,
  toggleInsightPane,
} = chatSlice.actions;

// Export for insight pane
export const openInsightsPane = (message) => {
  return (dispatch) => {
    dispatch(setInsights(`Insights for message: ${message.text}`)); // Set insights
    dispatch(toggleInsightPane()); // Open the pane
  };
};

// Slice export for registering in the store (the store is in /app and is the global state)
export default chatSlice.reducer;