import React from 'react';
import styles from './index.module.css'; // Importing the CSS Module for FloatingBoxA

const FloatingBoxA = ({ imageSrc, altText, title, description, imageClassName }) => {
  return (
    <div className={styles.floatingBoxA}>
      <div className={styles.imgContainer}>
        <img
          src={imageSrc}
          alt={altText}
          className={`${styles.ComponentImage} ${imageClassName}`} // Combine default and custom class names
        />
      </div>
      <div className={styles.imageText}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default FloatingBoxA;
