import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, IconButton } from '@fluentui/react';
import Thread from '../thread-component/thread-component';
import styles from './chat-component.module.css';
import { openInsightsPane, fetchAiResponse} from '../../../features/chatSlice';
import { panelOne, sendButton, textFieldOne } from '../../../styles/fluentStyles';
import InsightPane from '../insight-component/insight';
import { toggleControlMenu } from "../../../features/chatSlice";
import MainPane from '../mainPane/mainPane';
import { setCurrentConversation, fetchExistingConversations, createNewConversation, setIsNewConversation  } from '../../../features/conversationsSlice';
import { FiAlignJustify } from "react-icons/fi";
import TextBox from '../textbox-component/TextBox';


const Chat = () => {
  const [input, setInput] = useState('');
  const dispatch = useDispatch();
  const currentConversation = useSelector((state) => state.conversations.currentConversation);
  const conversations = useSelector((state) => state.conversations.conversations);
  const isNewConversation = useSelector((state) => state.conversations.isNewConversation);
  

  useEffect(() => {
    // Fetch existing conversations when the component mounts
    

    // Create a new conversation if none is selected
    if (!currentConversation) {
      dispatch(createNewConversation()).then((action) => {
        dispatch(setIsNewConversation(true));
        if (action.payload && isNewConversation) {
          dispatch(setCurrentConversation(action.payload));
        }
      });
    }
  }, [dispatch, currentConversation ]);

  const handleSend = () => {
    if (input.trim() !== '') {
      // Dispatch thunk to handle both user message addition and AI response
      dispatch(fetchAiResponse(input.trim()));
      setInput('');
    }
  };

  const isMenuOpen = useSelector((state) => state.chatbot.isControlMenuOpen);
  
  const handleControlMenu = () => {
      
      dispatch(toggleControlMenu());
    };

 
  return(
    <div className={styles.chatContainer}>
      <InsightPane />
      <button className={styles.cButton} onClick={handleControlMenu} ><FiAlignJustify /></button>
      {isMenuOpen && <MainPane />}
      <div className={styles.thread}>
        {currentConversation && 
          <Thread
            onInsightClick={(message) => dispatch(openInsightsPane(message))}
            messages={conversations[currentConversation]} 
          />
        }
        <TextBox />
      </div>
    </div>
    )
};

export default Chat;
