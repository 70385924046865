import React, { useEffect, useRef } from 'react';
import styles from './thread-component.module.css';
import UserMessage from '../message-component/user-message';
import BotMessage from '../message-component/bot-message';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';
import anim from '../../../components/animations/animations.json';

const Thread = () => {
    const threadRef = useRef(null);
    const currentConversationId = useSelector((state) => state.conversations.currentConversation);
    
    const messages = useSelector((state) => {
        if (currentConversationId) {
            return state.conversations.conversations[currentConversationId] || [];
        }
        return [];
    });

    useEffect(() => {
        // Scroll to the bottom when messages change
        if (threadRef.current) {
            threadRef.current.scrollTop = threadRef.current.scrollHeight;
        }
    }, [messages]);

    const lastBotMessageIndex = messages
    .slice()
    .reverse()
    .findIndex((msg) => msg.sender === "bot");
    const lastBotMessage =
    lastBotMessageIndex !== -1 ? messages[messages.length - 1 - lastBotMessageIndex] : null;

    return (
        <div className={styles.container}>
            <div className={styles.thread} ref={threadRef}>
                {messages.length > 0 ? (
                    messages.map((msg, index) =>
                        msg.sender === 'user' ? (
                            <UserMessage key={index} message={msg} />
                        ) : (
                            <BotMessage key={index} message={msg} isLastMessage={msg === lastBotMessage}/>
                        )
                    )
                ) : (
                    <div className={styles.threadTitle}>
                        <h1>endecta</h1>
                        <Lottie
                          loop
                          animationData={anim}
                          play
                          style={{ width: 25, height: 25 }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Thread;