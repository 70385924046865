import React, { useState } from "react";
import styles from "./textbox.module.css";
import { panelOne, sendButton, textFieldOne } from '../../../styles/fluentStyles';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, IconButton } from '@fluentui/react';
import { fetchAiResponse } from "../../../features/chatSlice";

const TextBox = () => {
    const [input, setInput] = useState('');
    const dispatch = useDispatch();
    const handleSend = () => {
        if (input.trim() !== '') {
          // Dispatch thunk to handle both user message addition and AI response
          dispatch(fetchAiResponse(input.trim()));
          setInput('');
        }
      };

    return (
        <div className={styles.textboxContainer}>
          <form className={styles.inputContainer} onSubmit={(e) => { e.preventDefault(); handleSend(); }}>
          <TextField
            autoAdjustHeight
            multiline
            placeholder='Type a message to endecta a.i ...'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={textFieldOne}
            rows={1}
            cols={1}
            resizable={false}
          />
          <IconButton 
            className={sendButton}
            iconProps={{ iconName: 'Send' }}
            text="submit" 
            onClick={handleSend} 
          />
        </form>
      </div>
    )


};

export default TextBox;