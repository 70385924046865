import React from "react";
import ChatComponent from "../../components/ChatComponents/chat-component/chat-component";
import styles from "./ChatPage.module.css";

const ChatPage = () => {
    return (
        <div className={styles.cc}>
            <ChatComponent />
        </div>
    );
};

export default ChatPage;