import React from "react";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";

const SectionFive = () => {

    const { t } = useTranslation();

    return (
        <div className={styles.main}>         
            <div className={styles.boxLeft}>
                <div className={styles.boxLeftText}>
                    <h1 style={{ marginBottom: '20px' }}>{t('SectionFive.LeftColumn.title')}</h1>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFive.LeftColumn.description1')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFive.LeftColumn.description2')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFive.LeftColumn.description3')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFive.LeftColumn.description4')}</h3>
                </div>   
            </div>
            <div className={styles.boxRight}>
                <div className={styles.headerText}>
                    <h1 style={{ marginBottom: '20px' }}>{t('SectionFive.RightColumn.title')}</h1>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFive.RightColumn.subtitle')}</h3>
                </div>
            </div>
        </div>
    );
};

export default SectionFive;
