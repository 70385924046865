import React from "react";

const AboutPage = () => {
    return (
        <div>
        <h1>About Page</h1>
        </div>
    );
};

export default AboutPage;