import {mergeStyles} from "@fluentui/react";

export const messageButtons = mergeStyles({
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '3px',
    color: 'white',
    selectors: {
        ':hover': {
            backgroundColor: 'transparent',
        },
        ':active': {
            backgroundColor: 'transparent',
        },
    },
});

export const sendButton = mergeStyles({
    marginLeft: '10px',
    color: 'white',
    scale: '1.3',
});

export const textFieldOne = mergeStyles({
    width: '100%',
    backgroundColor: 'transparent',
    selectors: {
        ':focus': {
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
        },
        '::before': {
            border: 'none',
        },
        '::after': {
            border: 'none',
        },
        '.ms-TextField-fieldGroup': {
            backgroundColor: 'rgb(51, 51, 51)',
            border: 'none',
            borderRadius: '30px',
            minHeight: '10px !important',
            selectors: {
                ':focus': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                },
                '::after': {
                    border: 'none',
                },
            },
        },
        '.ms-TextField-wrapper': {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: '30px',
        },
        '.ms-TextField-field': {
            backgroundColor: 'transparent',
            border: 'none',
            width: '100%',
            selectors: {
                ':focus': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                },
            },
        },
        'textarea': {
            minHeight: '30px',
            backgroundColor: 'rgb(51, 51, 51)',
            color: 'white',
            
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            padding: '10px',
            margin: '5px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            fontSize: 'var(--font-size-mdplus)',
            fontWeight: '200',
            selectors: {
                ':focus': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                },
                '::after': {
                    border: 'none',
                },
            },
        },
    },
});

export const panelOne = mergeStyles({
    backgroundColor: 'rgba(50, 0, 50, 0.2)',
    color: 'white',
    '.ms-Panel-main': {
        backgroundColor: 'rgb(70, 70, 70) !important',
        color: 'white',
        width: '40%',
    },  
    '.ms-Panel-commands': {
        backgroundColor: 'rgb(70, 70, 70)',
        color: 'white',
        textAlign: 'center',

    },
    '.ms-Panel-headerText': {
        backgroundColor: 'rgb(70, 70, 70)',
        color: 'white',
    },
    '.ms-Button': { color: 'white' },
});
