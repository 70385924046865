import axios from 'axios';
import {store} from "../app/store";


/*
-------------------------------------------------------------------------------
Chat API File
_____________

- The chat API file is not an actual API with endpoints.
- This creates API requests to the backend to send messages to the AI model.
- It is responsible for sending the message to the backend and returning the response.
- The metadata is passed to this from the REDUX ChatSlice file.
-------------------------------------------------------------------------------
*/


export const sendMessageToAI = async (message, token, user_id, session_id) => {

    const state = store.getState();
    const accessToken = state.auth.user?.accessToken;

    if (!accessToken) {
        throw new Error("Missing access token");
      }
    try {
        // Create the payload for the request
        const timestamp = new Date().toISOString();
        const payload = {
            user_id: user_id,       // oid of the user
            session_id: session_id, // Unique session identifier
            question: message,
            timestamp: timestamp,
        };

        // Send the request to the backend
        const response = await axios.post(
            "https://sl-apim.azure-api.net/api/ai/chat",  // Update endpoint URL as required
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,  // Include JWT for authentication
                },
            }
        );
        console.log("Response from AI received!")
        // Return the response message
        return response.data.message.predictions;
    } catch (ex) {
        console.error('Error sending message to AI:', ex);
        throw ex;
    }
};

/* 
===============================================================================
Retrieving Chat Conversations
================================================================================
Below is a script to use the same SQL Statements API to retrieve the sessions
for a user based on there use ID. 

This means that each session will be a conversation or thread on the frontend. 

Linking the two involves this process or sequence of events. 
1. The user logs in and sees either no chat sessions or all the previous
    chat sessions they have had.
    - To do this, we will have each conversation they create, compute a 
    session_id and store it in the state of the chat slice.
    - Only when they send there first message will the chat conversation be 
    persistently saved in the delta table. 
2. Once the session_id from the conversation is stored by initiating the 
    conversation with the Chain which will inturn trigger a delta table update
    with the API appending the new convo to the delta table. 
===============================================================================
*/

export const fetchChatsFromDeltaTable = async () => {
    try {
        const state = store.getState();
        const accessToken = state.auth.user?.accessToken;
        const user_id = state.auth.user?.oid;
        const payload = {
            user_id: user_id,
        }
        const response = await axios.post("https://sl-apim.azure-api.net/api/ai/getConversations",  // Update endpoint URL as required
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,  // Include JWT for authentication
                },
            }
        );
        console.log("Chat history retrieved!")
        return response.data;
    } catch (ex) {
        console.error('Error fetching existing conversations:', ex);
        throw ex;
    }
};