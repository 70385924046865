import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { loginUser } from "../features/authSlice";

const AuthCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loginUser()).then(() => {
            navigate('/');
        });
    }, [dispatch, navigate]);

    return (
        <div>
            <h1>Logging in...</h1>
        </div>
    );
}
export default AuthCallback;