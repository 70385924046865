import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from './homepage.module.css';
import HeaderOne from "../../components/MainComponents/HeaderOne";
import SectionOne from "../../components/MainComponents/SectionOne";
import SectionTwo from "../../components/MainComponents/SectionTwo";
import SectionThree from "../../components/MainComponents/SectionThree";
import SectionFour from "../../components/MainComponents/SectionFour";
import SectionFive from "../../components/MainComponents/SectionFive";
import { motion, useScroll, useSpring } from "framer-motion";
import { useTranslation } from "react-i18next";
import FooterOne from "../../components/MainComponents/FooterOne";
import Lottie from 'react-lottie-player';
import anim from '../../components/animations/animations.json';
import { useSelector } from "react-redux";

const HomePage = () => {

  const { t, i18n } = useTranslation();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const refL = useRef(null);
  const refS1 = useRef(null);
  const refS2 = useRef(null);
  const orbRef = useRef(null);  // Ref for the container of the Lottie animation
  const textRef = useRef(null); // Ref for the text container
  const fontClass = useSelector((state) => state.font.fontClass);
  const screenState = useSelector((state) => state.design);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 1,
    damping: 200,  // Increased damping to slow down the effect
    restDelta: 0.001
  });

  // Typewriter effect state and logic
  const [displayedText, setDisplayedText] = useState(''); // State to hold the currently displayed text
  const fullText = t('HomePage.title');  // Get the full text to be typed out (e.g., "Endecta")
  const typingSpeed = 35;  // Adjust the typing speed
  const initialDelay = 800;  // Delay before starting the typing effect (in ms)
  
  const [letterWidth, setLetterWidth] = useState(0);  // Adjust this value according to the font size and letter spacing

  // Variables for positioning the orb
  //const letterWidth = 30;  Adjust this value according to the font size and letter spacing

  const {isMobile} = screenState;
  const [baseX, setBaseX] = useState(30);
  const [baseY, setBaseY] = useState(-90); // Base Y offset to align the orb with the text vertically

  



  
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  let ref;
  let offset = 100;

  const handleLinkClick = (section) => {
    switch (section){
      case 'section0':
        ref = textRef;
        break;
      case 'section1':
        ref = section4Ref
        break;
      case 'section2':
        ref = section4Ref
        break;
      case 'section3':
        ref = section4Ref;
        break;
      case 'section4':
        ref = section3Ref;
        break;
      case 'section5':
        ref = section3Ref;
        break;
      default:
        break;
    }

    if (ref && ref.current) {
      const topPosition = ref.current.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }
  }


  useEffect(() => {
    switch (fontClass) {
      case 'jp-font':
        setLetterWidth(isMobile ? 32 : 45);
        setBaseY(isMobile ? -60 : -80);
        break;
      case 'en-font':
        setLetterWidth(isMobile ? 20 : 30);
        break;
      default:
        setLetterWidth(30);
        break;
    }
  }, [fontClass, isMobile]);

  const startTyping = useCallback(() => {
    let index = 0;  // Initialize the index to start typing from the first character

    // Set the initial position of the orb before the typing starts
    if (orbRef.current) {
      orbRef.current.style.transform = `translate(${baseX}px, ${baseY}px)`;  // Initial position before typing starts
      orbRef.current.style.visibility = 'hidden';  // Make the orb invisible initially
    }

    // Function to handle typing effect
    const typeText = () => {
      if (index <= fullText.length) {  // Ensure the index stays within bounds
        if (orbRef.current && index === 1) {  // Make the orb visible when the first letter is typed
          orbRef.current.style.visibility = 'visible';
        }

        setDisplayedText(fullText.slice(0, index));  // Slice the string up to the current index and display it

        // Move the orb container along with the typing text
        if (orbRef.current) {
          orbRef.current.style.transform = `translate(${baseX + index * letterWidth}px, ${baseY}px)`;  // Adjust for horizontal and vertical alignment
        }

        index++;  // Move to the next character
        setTimeout(typeText, typingSpeed);  // Continue typing after a delay
      }
    };

    // Start typing effect with an initial delay
    setTimeout(typeText, initialDelay);
  }, [fullText, letterWidth, baseX, baseY, typingSpeed, initialDelay]);

  useEffect(() => {
    let observer;

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setDisplayedText(''); // Clear the displayed text before starting typing
          startTyping(); // Start typing when the element is in view
        }
      });
    };

    if (textRef.current) {
      observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.1, // Adjust threshold as needed
      });
      observer.observe(textRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (observer && textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, [startTyping]);

  const getH1Class = () => {
    
    switch (fontClass) {
      case 'jp-font':
        return styles.jpHeader;
      case 'en-font':
        return styles['enHeader'];
      default:
        return styles.enFont;
    }
  };

  
  

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <HeaderOne handleLinkClick={handleLinkClick}/>
      </div>
      <motion.div ref={refL} className={styles.page}>
        <motion.div className={styles.landing} ref={textRef}>
          {/* Display the currently typed text */}
          <div className={styles.title}>
            <h1 >
              {displayedText}
            </h1>
            <div className={styles.orb}>
              <Lottie
                loop
                animationData={anim}
                play
                style={{ width: 40, height: 40 }}
              />
            </div>
          </div>
          <h3>{t('HomePage.subtitle')}</h3>
          <p>{t('HomePage.description')}</p>
        </motion.div>
        
        <motion.div className={styles.section1} ref={section1Ref}>
          <SectionOne />
        </motion.div>

        <motion.div  className={styles.section2}>
          <SectionTwo/>
        </motion.div>
        
        <motion.div className={styles.section3}>
          <SectionThree/>
        </motion.div>

        <motion.div className={styles.section4} >
          <SectionFour section4Ref={section4Ref}/>
        </motion.div>
        
        <motion.div className={styles.section5} ref={section3Ref}>
          <SectionFive />
        </motion.div>
      </motion.div>

      <footer>
        <FooterOne/>
      </footer>
      <motion.div className="progress" style={{ scaleX }} />
    </div>
  );
};

export default HomePage;
