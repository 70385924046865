import React, {useEffect} from 'react';
import styles from './conversations.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentConversation } from '../../../features/conversationsSlice';
import { fetchExistingConversations } from '../../../features/conversationsSlice';

const Conversation = () => {
    const dispatch = useDispatch();
    const conversations = useSelector((state) => state.conversations.conversations);
    const userId = useSelector((state) => state.auth?.user?.oid);
    const currentConversation = useSelector((state) => state.conversations.currentConversation); // Access current conversation from Redux

    useEffect(() => {
        if (userId && !Object.keys(conversations).length) {
          // Only fetch conversations if they haven't been loaded already
          dispatch(fetchExistingConversations());
        }
      }, [userId, dispatch]); 

    const handleConversationClick = (sessionId) => {
        
        dispatch(setCurrentConversation(sessionId));
        
    };

    return (
        <div className={styles.cCont}>
            <h1 className={styles.title}>Conversations</h1>
            {/* Check if conversations exist */}
            <div className={styles.conversations}>
                {Object.keys(conversations).length > 0 ? (
                    Object.keys(conversations).map((sessionId) => (
                        <div 
                            key={sessionId} 
                            className={styles.session} 
                            onClick={() => handleConversationClick(sessionId)}
                        >
                             <h2>Session ID: {sessionId.slice(8, 13)}....</h2>
                             <p>{conversations[sessionId].length} messages</p>
                        </div>
                    ))
                ) : (
                    <p>No conversations found.</p>
                )}
            </div>
        </div>
    );
}

export default Conversation;