import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchChatsFromDeltaTable } from '../api/chatApi';
import { v4 as uuidv4 } from 'uuid';





export const fetchExistingConversations = createAsyncThunk(
    'conversations/fetchExistingConversations',
    async (_, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        const user_id = state.auth?.user?.oid;
  
        if (!user_id) {
          throw new Error('Missing user information');
        }
  
        const conversations = await fetchChatsFromDeltaTable(user_id);
        const structuredConversations = {};
        
        conversations.message.forEach((message) => {
          const [
            userId,
            sessionId,
            timestamp,
            messageContext,
            messageRole,
            messageId,
            tokenUsage,
            retriever_metadata,
         ] = message;
          
          if (!structuredConversations[sessionId]) {
            structuredConversations[sessionId] = [];
          }
  
          structuredConversations[sessionId].push({
            userId,
            sessionId,
            timestamp,
            text: messageContext,
            sender: messageRole,
            messageId,
            tokenUsage,
            retriever_metadata,
          });
        });
        
        return structuredConversations;
      } catch (ex) {
        console.error('Error fetching existing conversations:', ex);
        return rejectWithValue(ex.message);
      }
    }
  );

  export const createNewConversation = createAsyncThunk(
    'conversations/createNewConversation',
    async (_, { getState, dispatch }) => {
      const state = getState();
      const user_id = state.auth?.user?.oid;
  
      if (!user_id) {
        throw new Error('Missing user information');
      }
  
      const newSessionId = `session-${uuidv4()}`;
      dispatch(setCurrentConversation(newSessionId));
      return newSessionId;
    }
  );

  const conversationsSlice = createSlice({
    name: 'conversations',
    initialState: {
      conversations: {},
      currentConversation: null,
      isNewConversation: false,
      error: null,
      loading: false,
    },
    reducers: {
      setCurrentConversation(state, action) {
        state.currentConversation = action.payload;
      },
      updateConversationMessages(state, action) {
        const { sessionId, messages } = action.payload;
        if (!state.conversations[sessionId]) {
          state.conversations[sessionId] = [];
        }
        state.conversations[sessionId] = [
          ...state.conversations[sessionId],
          ...messages,
        ]; // Append new messages
      },
      setIsNewConversation(state, action) {
        state.isNewConversation = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchExistingConversations.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchExistingConversations.fulfilled, (state, action) => {
          state.conversations = action.payload;
          state.loading = false;
        })
        .addCase(fetchExistingConversations.rejected, (state, action) => {
          state.error = action.payload;
          state.loading = false;
        });
    },
  });
   
export const { setCurrentConversation, updateConversationMessages, setIsNewConversation } = conversationsSlice.actions;
export default conversationsSlice.reducer;

/* 

"[{"page_content":"Remote control, signaling, and\npower-limited\ncircuits, 725.135(C),\nTable 725.154\nPliable raceways , 362.2\nPlugging boxes, motion picture\nstudios , 530.14,\n530.15(D), 530.18(E),\n530.18(G)\nDefinition,  530.2\nPlugs, attachment ,   see Attachment\nplugs\nPoint of common coupling , 692.2\nPoint of entrance\nDefinition,  770.2, 800.2, 820.2,\n830.2\nPolarization\nAppliances, 422.40\nConnections, 200.11\nLuminaires, 410.50\nPlugs, receptacles, and\nconnectors, 200.10(B)\nPortable handlamps, 410.82(A)\nReceptacle adapters, \n406.10(B)(3)\nScrew-shell-type\nlampholders, 410.50\nPoles\nCommunications wiring\nCoaxial cables, 820.44(A)\nCommunications\nwiring, 800.44(A)\nNetwork-powered broadband\ncommunications\ncables, 830.44(A)\nOptical fiber  cables, 840.44(A)","metadata":{"path":"dbfs:/Volumes/processing_catalog/processing_schema/source_docs/NFPA70-NEC-CODE-BOOK-v2017.pdf","chunk_id":"d392e9b2c4ace87be2ffc6a8d6b6a1ef"},"type":"Document"},{"page_content":"Part II. Circuit Wiring ....................................................... 70– 588\n Part III. Sources of Power .................................................. 70– 588\n Part IV. Overcurrent Protection ....................................... 70– 589\n702 Optional Standby Systems ................................... 70– 589\n Part I. General ................................................................. 70– 589","metadata":{"path":"dbfs:/Volumes/processing_catalog/processing_schema/source_docs/NFPA70-NEC-CODE-BOOK-v2017.pdf","chunk_id":"2adf78599c7947a01ffefab22d21fecd"},"type":"Document"}]"
*/