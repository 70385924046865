import React, { useEffect, useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styles from "./index.module.css"; // Importing the CSS Module for SectionOne
import azureimg from '../../../assets/azure.png';
import dbrximg from '../../../assets/databricks.png';
import langchainimg from '../../../assets/langchain.png';
import mlflowimg from '../../../assets/mlflow.png';
import FloatingBoxA from "../Webcomponents/FloatingboxA"; // Importing the FloatingBoxA component
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {useSelector} from "react-redux";

const SectionOne = () => {

    const { t } = useTranslation(); 
    const {isMobile, isTablet, isDesktop} = useSelector((state) => state.design);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,  // Lowered the threshold for a more gradual trigger
    });

    function useParallax(value, distance) {
        return useTransform(value, [0, 1], [-distance, distance]);
    }

    const ref2 = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref2 });
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 1,
        damping: 100,
        restDelta: 0.001
    });

    const [valueY, setValueY] = useState(200);
    const [doParallax, setDoParallax] = useState(true);

    useEffect(() => {
        if (isMobile || isTablet) {
            setValueY(10);
            setDoParallax(false);
        } else {
            setValueY(200);
            setDoParallax(true);
        }
    }, [isMobile, isTablet]);

    // Always call the hook
    const parallaxY = useParallax(scrollYProgress, valueY);

    // Conditionally use the result
    const y1 = doParallax ? parallaxY : 0;
    
   

    return (
        <motion.div  
            className={styles.main}
            ref={ref2}
        >           
            <motion.div 
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}  
                className={styles.grid}>

                {/* First grid item with FloatingBoxA */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={azureimg}
                        altText={"Azure"}
                        title={t('SectionOne.Box1.title')}
                        description={t('SectionOne.Box1.subtitle')}
                        imageClassName={styles.azureImage} // Passing the class from CSS module
                    />
                </div>

                {/* Second grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={dbrximg}
                        altText="Databricks"
                        title={t('SectionOne.Box2.title')}
                        description={t('SectionOne.Box2.subtitle')}
                        imageClassName={styles.databricksImage} // Passing the class from CSS module
                    />
                </div>

                {/* Third grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={langchainimg}
                        altText="Langchain"
                        title={t('SectionOne.Box3.title')}
                        description={t('SectionOne.Box3.subtitle')}
                        imageClassName={styles.langchainImage} // Passing the class from CSS module
                    />
                </div>
                
                {/* Fourth grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={mlflowimg}
                        altText="MLflow"
                        title={t('SectionOne.Box4.title')}
                        description={t('SectionOne.Box4.subtitle')}
                        imageClassName={styles.mlflowImage} // Passing the class from CSS module
                    />
                </div>
                
            </motion.div>

            <motion.div className={styles.headerText}>
                <motion.h1 style={{ y: y1 }}>{t('SectionOne.title')}</motion.h1>
                <motion.h3 style={{ y: y1 }}>{t('SectionOne.subtitle')}</motion.h3>
            </motion.div>
        </motion.div>
    );
};

export default SectionOne;
