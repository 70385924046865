import React from 'react';
import { Panel } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleInsightPane } from '../../../features/chatSlice';
import { panelOne } from '../../../styles/fluentStyles';

const InsightPane = () => {
    
    const dispatch = useDispatch();
    const isInsightPaneOpen = useSelector((state) => state.chatbot.isInsightPaneOpen);
    const insights = useSelector((state) => state.chatbot.insights);
    

    return (
        <Panel
            isOpen={isInsightPaneOpen}
            onDismiss={() => { dispatch(toggleInsightPane()) }}
            headerText="Insights"
            closeButtonAriaLabel="Close"
            className={panelOne}
        >
            {insights.length > 0 ? (
                insights.map((insight, index) => (
                    <div key={index} className="insight-item" style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
                        {insight.metadata && (
                            <div className="metadata" style={{ marginBottom: '10px' }}>
                                <strong>Metadata Path:</strong> {insight.metadata.path}
                            </div>
                        )}
                        {insight.page_content && (
                            <div className="page-content" style={{ marginBottom: '10px' }}>
                                <strong>Page Content:</strong> {insight.page_content}
                            </div>
                        )}
                        {insight.type && (
                            <div className="type">
                                <strong>Type:</strong> {insight.type}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <p>No insights available.</p>
            )}
        </Panel>
    );
};

export default InsightPane;