import React, {useRef, useState, useEffect} from "react";
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styles from "./index.module.css";
import FloatingBoxA from "../Webcomponents/FloatingboxA";
import openaiimg from '../../../assets/openai.png';
import metaimg from '../../../assets/meta.png';
import deepmindimg from '../../../assets/deepmind.png';
import huggingfaceimg from '../../../assets/Huggingface.png';
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

const SectionTwo = () => {
    const {isMobile, isTablet, isDesktop} = useSelector((state) => state.design);
    const { t } = useTranslation();

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,  // Lowered the threshold for a more gradual trigger
    });

    function useParallax(value, distance) {
        return useTransform(value, [0, 1], [-distance, distance]);
    }

    const ref2 = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref2 });
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 1,
        damping: 100,
        restDelta: 0.001
    });
    
    const [valueY, setValueY] = useState(200);
    const [doParallax, setDoParallax] = useState(true);

    useEffect(() => {
        if (isMobile || isTablet) {
            setValueY(10);
            setDoParallax(false);
        } else {
            setValueY(200);
            setDoParallax(true);
        }
    }, [isMobile, isTablet]);

    // Always call the hook
    const parallaxY = useParallax(scrollYProgress, valueY);

    // Conditionally use the result
    const y1 = doParallax ? parallaxY : 0;

    return (
        <motion.div  
            className={styles.main}
            ref={ref2}
        >           
            <motion.div 
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}  
                className={styles.grid}>

                {/* First grid item with FloatingBoxA */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={openaiimg}
                        altText="Azure"
                        title={t('SectionTwo.Box1.title')}
                        description={t('SectionTwo.Box1.subtitle')}
                        imageClassName={styles.openaiImage} // Passing the class from CSS module
                    />
                </div>

                {/* Second grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={metaimg}
                        altText="Databricks"
                        title={t('SectionTwo.Box2.title')}
                        description={t('SectionTwo.Box2.subtitle')}
                        imageClassName={styles.metaImage} // Passing the class from CSS module
                    />
                </div>

                {/* Third grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={deepmindimg}
                        altText="Langchain"
                        title={t('SectionTwo.Box4.title')}
                        description={t('SectionTwo.Box4.subtitle')}
                        imageClassName={styles.deepmindImage} // Passing the class from CSS module
                    />
                </div>
                
                {/* Fourth grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={huggingfaceimg}
                        altText="MLflow"
                        title={t('SectionTwo.Box3.title')}
                        description={t('SectionTwo.Box3.subtitle')}
                        imageClassName={styles.huggingfaceImage} // Passing the class from CSS module
                    />
                </div>
                
            </motion.div>

            <motion.div className={styles.headerText}>
                <motion.h1 style={{ y: y1 }}>{t('SectionTwo.title')}</motion.h1>
                <motion.h3 style={{ y: y1 }}> {t('SectionTwo.subtitle')} </motion.h3>
            </motion.div>
        </motion.div>
    );
};

export default SectionTwo;
