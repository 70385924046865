export const translations = {
    en: {
      header: {
        companyName: "endecta",
        links: ["about", "contact","funding","technology","demo"],
      },
        HomePage: {
            title: "endecta",
            subtitle: "Creating full stack AI assistant reasoning technology",
            description: "Our framework combines advanced generative AI with enterprise level software to replicate near-human competency in strategic workflows, enhancing professional tasks in real time",
        },
        SectionOne: {
            title: "An end-to-end generative A.I foundation",
            subtitle: "Our innovative framework seamlessly integrates secure, real-time, high-capacity data ingestion with advanced parsing capabilities for diverse file types. Powered by our Instruction + Retrieval Augmentation + Assistant (IRAA) and the Endecta Cognitive Assistant Platform (EACP). We support third party API integrations and full customization",
            Box1: { 
                title : "Azure PaaS and Cognitive Services",
                subtitle : "Scalable, secure, and reliable cloud services forming the backbone of our infrastructure",
            },
            Box2: { 
                title : "Databricks",
                subtitle : "Harnessing the power of Databricks and Apache Spark for efficient data parsing and vectorization",
            },
            Box3: { 
                title : "Langchain",
                subtitle : "Managing data flow decisions through our robust Langchain framework",
            },
            Box4: { 
                title : "Mlflow",
                subtitle : "Cutting-edge machine learning platform that delivers the latest insights in near real-time",
            },
        },   
        
        SectionTwo: {
            title: "Cognition. Get Work Done. 40% Efficiency",
            subtitle: "At the core of our AI assistant is the most current model per task and industry. We have the ability to review the process required by the client then apply and fine-tune the model to the task at hand.",
            Box1: { 
                title : "OpenAI",                 
                subtitle : "The most advanced AI models drive our assistant's performance",
            },
            Box2: { 
                title : "Meta",
                subtitle : "Leveraging Meta’s cutting-edge AI research to enhance strategic decision-making",
            },
            Box3: { 
                title : "Hugging Face",
                subtitle : "Utilizing Hugging Face’s robust NLP models for seamless, multimodal communication",
            },
            Box4: { 
                title : "Google DeepMind",
                subtitle : "Integrating Google’s advanced AI techniques for enhanced cognitive capabilities",
            },
        },   
        SectionThree: {
            title: "Customize. Integrate. Analyze. Visualize.",
            subtitle: "We've integrated into the most powerful enterprise tools, enabling organizations to harness AI-driven insights and stay ahead of the curve. Designed for high-level tasks such as engineering and construction project management, our assistant combines human-like interaction with advanced machine learning analysis. It leverages empirical code interpreters for precise calculations, ensuring accuracy in complex scenarios. ",
            Box1: { 
                title : "Office 365 Suite",
                subtitle : "Seamless integration with Office 365 for productivity and collaboration",
            },
            Box2: { 
                title : "Custom Endecta Assistant UI",
                subtitle : "An animated UI offering human-like interaction with vision, speech, and cognitive abilities for natural conversations and a better user experience",
            },
            Box3: { 
                title : "Tableau",
                subtitle : "AI-powered analytics with Tableau for data-driven decision-making",
            },
            Box4: { 
                title : "Power BI",
                subtitle : "Leveraging Power BI’s capabilities for comprehensive business intelligence",
            },
        },   
        SectionFour: {
            LeftColumn: { 
                
                title: "About Endecta",
                
                description1: "Endecta is a California Bay Area based advanced reasoning artificial intelligence company. We intend to revolutionize the way organizations approach complex, strategic tasks. Our full-stack AI assistant is designed to replicate near-human competency. Our innovative platform combines the power of generative AI with advanced enterprise software, offering real-time, multi-step reasoning workflows that enhance efficiency and productivity. Our mission is to empower businesses to achieve their goals with precision and intelligence, all through a seamless AI-driven experience.", 
                
                description2: "At the heart of Endecta's offerings is our groundbreaking Instruction + Retrieval Augmentation + Assistant (IRAA) framework. This powerful system integrates secure, high-capacity data ingestion with advanced parsing capabilities, enabling our AI assistant to perform complex strategic thinking tasks effortlessly.", 
                
                description3: "Our AI assistant is designed to adapt to the specific needs of various industries, offering tailored solutions that combine the latest AI models with a natural language and visual interface. This allows users to interact with the AI intuitively, focusing on achieving their objectives rather than mastering complex software.",
                
                description4: "Endecta is not just about technology; it's about transforming the way businesses operate. By integrating our AI assistant into powerful enterprise tools, we enable organizations to unlock new potentials, meet quality and labor goals, drive innovation, and maintain a strategic advantage in their respective fields. With a focus on accuracy, efficiency, and user experience, Endecta is shaping the future of intelligent enterprise solutions.",
            },
            RightColumn: { 
                title1 : "Funding and Partnerships",
                description1 : "We are collaborating with select engineering firms to develop our beta platform. Additionally, we are in the process of securing a $3.5 million USD seed round with Silicon Valley Bank. If you're interested in partnering with us or investing in our technology, please reach out for more information.",

                title2 : "Contact Us!",
                description2 : "P.O. Box 212 1346 The Alameda STE #7 San Jose, CA 95126",
                description3 : "Email us at contact@endecta.com",
            },
        },   
        SectionFive: {
            LeftColumn: { 
                
                title: "Our Technology",
                                
                description1: "Endecta's technology integrates cutting-edge AI with robust enterprise software, delivering unparalleled performance and scalability. Our platform is powered by Azure PaaS, Databricks, Langchain, and MLflow, ensuring a secure, scalable, and reliable infrastructure for modern enterprises.",
        
                description2: "At the core of our technology is the Instruction + Retrieval Augmentation + Assistant (IRAA) system, seamlessly integrated with enterprise tools. This system leverages advancements from OpenAI, Meta, Hugging Face, and Google DeepMind to manage workflows, enhance decision-making, and deliver real-time insights.",
                
                description3: "Our platform simplifies AI interactions with a natural language and visual interface, integrated with essential tools like Office 365, Tableau, and Power BI. The custom Endecta Assistant UI enhances user experience with human-like interaction capabilities.",
                
                description4: "Endecta is committed to enterprise-grade security and scalable solutions that evolve with your business needs. Our platform ensures secure AI integration at every level, driving efficiency, innovation, and a competitive edge."


            },
            RightColumn: { 
                title : "Demo",
                subtitle : "We will be providing demos late 2024. Please contact us for more information.",
            },
        },   
        Footer: {
            title: "P.O. Box 212 1346 The Alameda STE #7 San Jose, CA 95126",
            subtitle: "Email us at contact@endecta.com",
            
        },   
    },
    jp: {
      header: {
        companyName: "エンデクタ",
        links: ["私たちについて", "お問い合わせ", "技術", "資金調達", "ログイン"],
      },
    
        HomePage: {
        
            title: "エンデクタ",
            subtitle: "真のAI技術を創造する",
            description: "エンデクタは、戦略的思考ワークフローのために設計された、プロセスや設計環境での人間レベルの能力をシミュレートするための非常に強力な人工知能アシスタントを開発しています。",
            },
    },
}
