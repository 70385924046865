import React, {useRef, useState, useEffect} from "react";
import { motion, useScroll, useSpring, useTransform} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styles from "./index.module.css";
import office365img from '../../../assets/office365.png';
import powerbiimg from '../../../assets/powerbi.png';
import tableauimg from '../../../assets/tableau.png';
import FloatingBoxA from "../Webcomponents/FloatingboxA";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

const SectionThree = () => {
    const {isMobile, isTablet, isDesktop} = useSelector((state) => state.design);
    
    const { t } = useTranslation();

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
    });

    function useParallax(value, distance) {
        return useTransform(value, [0, 1], [-distance, distance]);
    }

    const ref2 = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref2 });
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 1,
        damping: 100,
        restDelta: 0.001
    });
    const [valueY, setValueY] = useState(200);
    const [doParallax, setDoParallax] = useState(true);

    useEffect(() => {
        if (isMobile || isTablet) {
            setValueY(10);
            setDoParallax(false);
        } else {
            setValueY(200);
            setDoParallax(true);
        }
    }, [isMobile, isTablet]);

    // Always call the hook
    const parallaxY = useParallax(scrollYProgress, valueY);

    // Conditionally use the result
    const y1 = doParallax ? parallaxY : 0;

    return (
        <motion.div  
            className={styles.main}
            ref={ref2}
        >           
            <motion.div 
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}  
                className={styles.grid}>

                {/* First grid item with FloatingBoxA */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={office365img}
                
                        title={t('SectionThree.Box1.title')}
                        description={t('SectionThree.Box1.subtitle')}
                        imageClassName={styles.office365Image} // Passing the class from CSS module
                    />
                </div>

                {/* Second grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        title={t('SectionThree.Box2.title')}
                        subtitle={t('SectionThree.Box2.subtitle')}
                        description={t('SectionThree.Box2.subtitle')}
                        
                    />
                </div>

                {/* Third grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={tableauimg}
                        title={t('SectionThree.Box3.title')}
                        description={t('SectionThree.Box3.subtitle')}
                        imageClassName={styles.tableauImage} // Passing the class from CSS module
                    />
                </div>
                
                {/* Fourth grid item */}
                <div className={styles.griditem}>
                    <FloatingBoxA
                        imageSrc={powerbiimg}
                        title={t('SectionThree.Box4.title')}
                        description={t('SectionThree.Box4.subtitle')}
                        imageClassName={styles.powerbiImage} // Passing the class from CSS module
                    />
                </div>
                
            </motion.div>

            <motion.div className={styles.headerText}>
            <motion.h1 style={{ y: doParallax ? y1 : 0 }}>{t('SectionThree.title')}</motion.h1>
            <motion.h3 style={{ y: doParallax ? y1 : 0 }}>{t('SectionThree.subtitle')}</motion.h3>
            </motion.div>
        </motion.div>
    );
};

export default SectionThree;
