import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import chatReducer from '../features/chatSlice';
import fontReducer from '../features/fontSlice';
import designSlice from '../features/designSlice';
import authSlice from '../features/authSlice';
import conversationsSlice from '../features/conversationsSlice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const rootReducer = combineReducers({
  chatbot: chatReducer,
  conversations: conversationsSlice,
  font: fontReducer,
  design: designSlice,
  auth: authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability check for redux-persist
    }),
});

const persistor = persistStore(store);

export { store, persistor };
