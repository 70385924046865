import React from "react";
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';


const FooterOne = () => {

    const { t } = useTranslation();

    return(
        <div className={styles.main}>
            <div className={styles.grid}>
                <div className={styles.title}>
                    <div className={styles.tText}>
                        <h2>Endecta LLC</h2>
                        <h3>{t('Footer.title')}</h3>
                        <h3>{t('Footer.subtitle')}</h3>
                    </div>
                </div>
            </div>  
        </div>
    )
};

export default FooterOne;