import { DefaultButton, MessageBar, Panel } from "@fluentui/react";
import React, { useEffect } from "react";
import styles from "./mainPane.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleControlMenu } from "../../../features/chatSlice";
import Conversation  from "../conversations/conversations";
import { fetchExistingConversations, createNewConversation, setCurrentConversation } from "../../../features/conversationsSlice"; // Import the createNewConversation thunk
import { FiAlignLeft } from "react-icons/fi";
import { AiFillPlusCircle } from "react-icons/ai";



const MainPane = () => {
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state) => state.chatbot.isControlMenuOpen);
    const isLoading = useSelector((state) => state.conversations.loading);
    const conversations = useSelector((state) => state.conversations.conversations);

    const handleControlMenu = () => {
        dispatch(toggleControlMenu());
      };
    // const handleRefresh = async () => {
    //     try {
    //         const response = await fetchChatsFromDeltaTable();
    //         console.log("Response from Delta Table: ", response);
    //     } catch (ex) {
    //         console.error('Error fetching existing conversations:', ex);
    //     }
    // };
    useEffect(()=> {
        console.log('Conversations Count: ' + Object.keys(conversations).length);
        const l = Object.keys(conversations).length
        if(l > 0){
            dispatch(fetchExistingConversations());
        }
    }, [dispatch]);

    const handleCreateNewConversation = async () => {
        try {
            // Create a new conversation and set it as the current conversation
            const action = await dispatch(createNewConversation());
            if (action.payload) {
                dispatch(setCurrentConversation(action.payload));
            }
        } catch (ex) {
            console.error('Error creating a new conversation:', ex);
        }
    };


    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <button className={styles.closeButton} onClick={handleControlMenu}><FiAlignLeft /></button>
                <button className={styles.addButton} onClick={handleCreateNewConversation}><AiFillPlusCircle /></button>
            </div>
            <div className={styles.threads}>
            {isLoading ? (
                    <div className={styles.loading}>Loading conversations...</div>
                ) : (
                    <Conversation />
                )}
            </div>
        </div>
    );
};
export default MainPane;