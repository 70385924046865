import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {loginUser, logoutUser} from '../../../features/authSlice';
import styles from './authButton.module.css';
import {useNavigate} from 'react-router-dom';

const AuthButton = () => {
    // Grabbing all variables from the Redux Store
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.auth.loading);
    const user = useSelector((state) => state.auth.user);
    // Assigning react-routers useNavigate to a variable this will be used later
    const navigate = useNavigate();
    // Trying to get a working varibale to only redirect the user once
    const { isAuthenticated, redirectAfterLogin } = useSelector(state => state.auth);
    const [isRedirected, setIsRedirected] = useState(false);


    //Handle authentication button function. Once called it will dispatch login or logout function to communicate with authSlice/B2C
    const handleAuth = () => {
        console.log("handleAuth");
        if (isAuthenticated) {
            dispatch(logoutUser());
        } else {
            try {
                dispatch(loginUser());
            } catch (e) {
                console.error(e);
            }
        }
    };

    // Use useEffect to redirect to /chat after login is successful, only once
    // useEffect(() => {
    //     if (!isRedirected && isAuthenticated) {
    //         setIsRedirected(true);
    //         navigate("/chat");
    //     }
    // }, [isAuthenticated, redirectAfterLogin, navigate]);

    return (
        <div className={styles.cont}>
            <p className={loading ? styles.loadingButton : isAuthenticated ? styles.loButton : styles.liButton} onClick= {handleAuth} disabled={loading}>
                {loading ? "logging in..." : isAuthenticated ? `logout, ${user.name}` : "login"}
            </p>
        </div>
    );
};
export default AuthButton;