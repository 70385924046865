import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css'; // Assuming you have a CSS module for styling

const SectionFour = ({section4Ref}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.main}>
            <div className={styles.boxLeft} ref={section4Ref}>
                <div className={styles.boxLeftText}>
                    <h1 style={{ marginBottom: '20px' }}>{t('SectionFour.LeftColumn.title')}</h1>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFour.LeftColumn.description1')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFour.LeftColumn.description2')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFour.LeftColumn.description3')}</h3>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFour.LeftColumn.description4')}</h3>
                </div>   
            </div>
            <div className={styles.boxRight}>
                <div className={styles.boxRightText}>
                    <h1 style={{ marginBottom: '20px' }}>{t('SectionFour.RightColumn.title1')}</h1>
                    <h3 style={{ marginBottom: '20px' }}>{t('SectionFour.RightColumn.description1')}</h3>
                    <h1 style={{ marginBottom: '20px' }}>{t('SectionFour.RightColumn.title2')}</h1>
                    <h2 style={{ marginBottom: '20px' }}>{t('SectionFour.RightColumn.description2')}</h2>
                    <h2 style={{ marginBottom: '20px' }}>{t('SectionFour.RightColumn.description3')}</h2>
                </div>
            </div>
        </div>
    );
};

export default SectionFour;


