/*
NOT A REDUX SLICE

==================
This uses react state managemnt to monitor the dom.
It will check if the token for the user needs to be refreshed. 
Without this, the token will expire after 15 minutes and they will need to log out
and log back in.
Then it will cause JWT to deactivate and will need to refresh the backend to activate it again. 

This is crucial to a seamless and consistent user experience.

*/

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAccessToken, logoutUser } from '../features/authSlice';

const TokenManager = () => {
    const dispatch = useDispatch();
    const expiresOn = useSelector((state) => state.auth.user?.expiresOn);
    
    useEffect(() => {
        // If there is no expiration value, there is no need to set up the interval
        if (!expiresOn) return;
    
        // Check immediately on component mount if the token has expired
        const timeLeft = new Date(expiresOn).getTime() - Date.now();
        if (timeLeft < 0) {
            dispatch(logoutUser());
            console.log("Token expired on load");
            return;
        }
    
        // Set up an interval to periodically check the remaining time of the token
        const interval = setInterval(() => {
            const remainingTime = new Date(expiresOn).getTime() - Date.now();
    
            // Refresh 2 minutes before expiry if there is still some time left
            if (remainingTime < 2 * 60 * 1000 && remainingTime > 0) {
                dispatch(refreshAccessToken());
                console.log("Token refreshed");
            }
    
            // Log out if the token has expired
            if (remainingTime < 0) {
                dispatch(logoutUser());
                console.log("Token expired");
            }
        }, 60 * 1000); // Check every minute
    
        // Clear the interval when the component is unmounted or when expiresOn changes
        return () => clearInterval(interval);
    }, [expiresOn, dispatch]);

    return null;
};

export default TokenManager;