import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    fontClass: 'en-font',
};

const fontSlice = createSlice({
    name: 'font',
    initialState,
    reducers: {
        setFontClass: (state, action) => {
            state.fontClass = action.payload;
        },
    },
});

export const {setFontClass} = fontSlice.actions;
export default fontSlice.reducer;