import { useEffect } from "react";
import {useDispatch} from 'react-redux';
import { updateResponsiveMetrics } from "../features/designSlice";


const DesignListener = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            dispatch(updateResponsiveMetrics());
        };

        window.addEventListener('resize', handleResize);


        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch]);

    return null;
};

export default DesignListener;