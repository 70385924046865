import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  isMobile: window.innerWidth <= 767,  // Example threshold for mobile
  isTablet: window.innerWidth > 767 && window.innerWidth <= 991,
  isDesktop: window.innerWidth > 991,
};

const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {
    updateResponsiveMetrics: (state) => {
      state.screenWidth = window.innerWidth;
      state.screenHeight = window.innerHeight;
      state.isMobile = window.innerWidth <= 767;
      state.isTablet = window.innerWidth > 767 && window.innerWidth <= 991;
      state.isDesktop = window.innerWidth > 991;
    },
  },
});

export const { updateResponsiveMetrics } = designSlice.actions;
export default designSlice.reducer;