import React, {useState, useEffect} from "react";
import styles from "./bot-message.module.css";
import { IconButton } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import {messageButtons} from "../../../styles/fluentStyles";
import { Provider, useDispatch, useSelector } from 'react-redux';
import chatSlice, { openInsightsPane, toggleInsightPane, setInsights } from '../../../features/chatSlice';
import { updatePartialMessage } from '../../../features/conversationsSlice';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";


const BotMessage = ({ message, isLastMessage }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.chatbot.loading);
    const isInsightPaneOpen = useSelector((state) => state.chatbot.isInsightPaneOpen);
    const [currentText, setCurrentText] = useState(""); // Track the visible portion of the text
    const [index, setIndex] = useState(0); // Track the current character index
    const CHUNK_SIZE = 1; // How many characters to reveal at a time
    const DELAY = 10; // Milliseconds delay between each update

    // Incrementally reveal the text using useEffect
    useEffect(() => {
        // Only stream if it's the last message
        if (isLastMessage && index < message.text.length) {
            const timer = setTimeout(() => {
                setCurrentText((prev) => prev + message.text[index]); // Add next character
                setIndex((prevIndex) => prevIndex + CHUNK_SIZE); // Move to the next character
            }, DELAY);

            return () => clearTimeout(timer); // Clean up the timer on unmount or message change
        }
    }, [index, isLastMessage, message.text]);

    const handleInsightClick = () => { 
        dispatch(toggleInsightPane());
        
        const ins = message.retriever_metadata || [];
        dispatch(setInsights(ins));
     };


    const renderers = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || ""); // Extract language from class
            return !inline && match ? (
                <SyntaxHighlighter
                    style={oneDark} // Apply the theme
                    language={match[1]} // Language extracted from class
                    PreTag="div"
                    {...props}
                >
                    {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
    };

    const loadingMessage = () => {
        return(
            <div className={styles.loader}>
                <p>Thinking...</p>
            </div>
        )
    }

    return (
        <div className={styles.botMessage}>
            <div className={styles.content}>
                <div className={styles.msg}>
                   <ReactMarkdown components={{ code: renderers.code }}>{isLastMessage ? currentText : message.text}</ReactMarkdown>
                </div>
                <div className={styles.msgDate}>
                    <span>{message.timestamp}</span>
                </div>
                <div className={styles.msgButtons}>
                    <IconButton
                        className={messageButtons} 
                        iconProps={{iconName: 'Copy'}} 
                        title="Copy" 
                        ariaLabel="Copy"
                        onClick={() => alert('Copy-to-Clipboard Button STC')}
                    />
                    <IconButton 
                        className={messageButtons} 
                        iconProps={{iconName: 'Glasses'}} 
                        title="Insight" 
                        ariaLabel="Insight"
                        onClick={handleInsightClick}
                    />
                </div>
            </div>
        </div>
    )
};

export default BotMessage;