import { PublicClientApplication } from "@azure/msal-browser";

// Define your Azure AD B2C tenant and policy configurations
const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_EndectaSignUpSignIn",
    signIn: "B2C_1_SignIn",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://endectab2c.b2clogin.com/endectab2c.onmicrosoft.com/B2C_1_EndectaSignUpSignIn",
    },
    passwordReset: {
      authority:
        "https://endectab2c.b2clogin.com/endectab2c.onmicrosoft.com/B2C_1_Reset",
    },
    signIn: {
      authority:
        "https://endectab2c.b2clogin.com/endectab2c.onmicrosoft.com/B2C_1_SignIn",
    }
  },
  authorityDomain: "endectab2c.b2clogin.com",
};  

// MSAL configuration object
export const msalConfig = {
  auth: {
    clientId: "4ad0075c-7064-43ca-933c-5abe584ecbae", // Client ID from Azure portal
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain], // Important to avoid instance discovery errors
    redirectUri: "https://endecta.com", // Your SPA redirect URI
  },
  cache: {
    cacheLocation: "sessionStorage", // Default cache location
    storeAuthStateInCookie: false, // Set to true if dealing with issues on IE11 or Edge
  },
};

// Scopes for login
export const loginRequest = {
  scopes: ["openid", "offline_access"],
};

// Scopes for accessing a protected resource
export const apiRequest = {
    scopes: [
      "https://endectab2c.onmicrosoft.com/endecta-webAPI/tasks.read",
      "https://endectab2c.onmicrosoft.com/endecta-webAPI/tasks.write"
    ],
  };

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);
