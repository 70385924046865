import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from  './ls.module.css';
import { useDispatch } from 'react-redux';
import { setFontClass } from '../../../../features/fontSlice';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (lng === 'jp') {
      dispatch(setFontClass('jp'));
    }else{
      dispatch(setFontClass('en'));
    }
  };

  return (
    <div className={styles.cont}>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('jp')}>日本語</button>
    </div>
  );
};

export default LanguageSwitcher;
